import React from "react";

import YellowUnderline from "../../../images/mlp-part-time/yellow-underline.png";

import tool1 from "../../../images/mlp-part-time/tool-1.webp";
// import tool2 from "../../../images/mlp-part-time/tool-1.webp";
import tool3 from "../../../images/mlp-part-time/tool-3.webp";
import tool4 from "../../../images/mlp-part-time/tool-4.webp";
import tool5 from "../../../images/mlp-part-time/tool-5.webp";
import tool6 from "../../../images/mlp-part-time/tool-6.webp";
import tool7 from "../../../images/mlp-part-time/tool-7.webp";
import tool8 from "../../../images/mlp-part-time/tool-8.webp";
import tool9 from "../../../images/mlp-part-time/tool-9.webp";
import tool10 from "../../../images/mlp-part-time/tool-10.webp";
import tool11 from "../../../images/mlp-part-time/tool-11.webp";
import tool12 from "../../../images/mlp-part-time/tool-12.png";
import tool13 from "../../../images/mlp-part-time/tool-13.webp";
import tool14 from "../../../images/mlp-part-time/tool-14.webp";
import tool15 from "../../../images/mlp-part-time/tool-15.webp";
import tool16 from "../../../images/mlp-part-time/tool-16.webp";
import tool17 from "../../../images/mlp-part-time/tool-17.webp";
import tool18 from "../../../images/mlp-part-time/tool-18.webp";

import "./styles.scss";

const ToolsCovered = () => {
  return (
    <div className="mlp-part-time-tools-covered">
      <div className="main-title">
        <h2 className="main-header">
                    What digital marketing tools will be covered?
        </h2>
        <img className="yellow-underline" src={YellowUnderline} alt="Yellow Underline" />
      </div>
      <h4 className="sub-title">
                Here’s a list of various digital marketing tools that you’ll be introduced to in the 
                part-time digital marketing course. 
      </h4>
      <div className="tools-used-container">
        <img src={tool1} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool13} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool3} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool4} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool5} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool6} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool7} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool8} style={{scale: "0.75"}} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool9} style={{scale: "1.5"}} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool10} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool11} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool12} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool13} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool14} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool15} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool16} style={{scale: "1.25"}} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool17} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src={tool18} alt="" loading="lazy" className="mlp-part-time-tool"/>
      </div>
    </div>
  );
};

export default ToolsCovered;