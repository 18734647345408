import React from "react";
import "./styles.scss";
import dottedBg from '../../../images/mlp-part-time/dottedLine.png';
import calenderIcon from '../../../images/mlp-part-time/calenderIcon.png';
import DottedMob from '../../../images/mlp-part-time/mobileDottedLines.png';
import DottedMob2 from '../../../images/mlp-part-time/mobileDottedLines2.png';

const CareerTrajectory = () => {
  return (
    <div className="mlp-part-time-career-trajectory">
      <h2 className="career-trajectory-sub-title">CAREER TRAJECTORY</h2>
      <div className="career-trajectory-header">
        Here’s what your days..months..years can look like in 2024 and beyond!
        <img className="calenderIcon" src={calenderIcon} loading='lazy' />
        <br />
      </div>
     
      <div className="career">
        <img src={dottedBg} className="dottedBg"  loading="lazy"/>
        <div className="column">
          <div className="career-card">
            <div className="title">
              <div className="indexNo">01</div><span className="title ml-mob-1">Digital Marketing Associate</span>
            </div>
            <div className="desc">
              Drive growth for the business using Paid Ads
            </div>
          </div>
          <div className="career-card">
            <div className="title alignTop">
              <div className="indexNo">01</div><span className="ml-mob-1">Ecommerce Ads Executive</span>
            </div>
            <div className="desc">
              Drive business growth  through Amazon, Flipkart etc
            </div>
          </div>
          <div className="career-card">
            <div className="title">
              <div className="indexNo">01</div>Growth Associate
            </div>
            <div className="desc">Drive growth for a brand beyond paid ads as well</div>
          </div>
        </div>
        <img className="mobDotted" src={DottedMob} loading="lazy" />
        <div className="flex">
          <div className="career-card">
            <div className="title">
              <div className="indexNo index-2">02</div>Marketing Manager
            </div>
            <div className="desc">
              Plan and execute strategies to drive results through your team
            </div>
          </div>
          <img className="mobDotted2" src={DottedMob2} loading="lazy" />

          <div className="career-card mtopMob-1">
            <div className="title">
              <div className="indexNo">03</div>Brand Head
            </div>
            <div className="desc">
              Build and execute strategies to help all organic marketing
              channels work together
            </div>
          </div>
          <img className="mobDotted2" src={DottedMob2} loading="lazy" />
          <img className="mobDotted2" src={DottedMob2} loading="lazy" />
          <div className="career-card yellowBorder">
            <div className="title alignTop">
              <div className="indexNo">04</div>Marketing Head
            </div>
            <div className="desc">Drive business results profitably</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerTrajectory;