import React from "react";
import yellowTicket from '../../../images/mlp-part-time/yellowTicket.png';
import "./styles.scss";

/*Card for Student Testemonial*/
function Card5({ name, description, link, title,subTitle }) {
  return (
    <div className="card-5-testimonials">
      <img src={yellowTicket} className="yellowTicket"  loading="lazy" />
      <div className="type2-info">
        <div>
          <div className="name">{name}</div>
          <div className="title">{title}</div>
        </div>
        <div>
          {subTitle && <div className="subTitle">{subTitle}</div>}
          <div className="type2-description1">{description}</div>
          <a className="dbb-program-card-btn" target="_blank" rel="noreferrer" href={link}>
            Explore Program
          </a>
        </div>
      </div>
    </div>
  );
}

export default Card5;